
@import "./shared/colors";
@import "./shared/common";
@import "../../node_modules/@splidejs/splide/dist/css/splide.min.css";

html {
    background-color: white;
}

header {
    .splide__slide {
        max-height: 750px;
        overflow: hidden;
    }

    .splide__progress__bar {
        height: 3px;
        background: #e91e25;
    }
}

nav {
    // background: linear-gradient(to right, #2664b1, #3a7fd5);
    // background: linear-gradient(to right, mediumslateblue, #3a7fd5);
    background-color: rgba(236, 27, 36, 0.8);
    -webkit-transition: box-shadow .3s, background-color .3s;
    -o-transition: box-shadow .3s, background-color .3s;
    -moz-transition: box-shadow .3s, background-color .3s;
    transition: box-shadow .3s, background-color .3s;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: auto;
    height: 50px;
    line-height: 50px;
    z-index: 1000;

    ul {
        a {
            color: white;
            
            &.active {
                background-color: #f69e03;
            }
        }
    }

    .nav-bar {
        position: absolute;
        right: 0;
        margin: auto;
    }

    .brand-logo {
        background: white;
        transform: skew(-20deg);
        padding-left: 10px;
        padding-right: 10px;

        img {
            max-height: 40px;
            vertical-align: middle;
            margin-top: -5px;
            transform: skew(20deg);
        }

        span {
            text-transform: uppercase;
            background: linear-gradient(to right, rgb(245, 229, 27), hsl(207deg, 83%, 75%), hsl(207deg, 83%, 75%), hsl(214deg, 100%, 61%));
                background-clip: border-box;
            background-clip: border-box;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0,0,0,0);
            font-family: "Source Sans Pro";
            font-size: 22px;
            font-weight: 800;
            position: relative;
            color: #fff;
            font-style: italic;
        }
    }

    .user-profile {
        // line-height: 52px;

        .profile-avatar {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            border: 2px solid #eeeeee;
            vertical-align: middle;
            margin-top: -4px;
        }

        .dropdown-content {
            top: 55px !important;
            min-width: 140px;
            right: 2px;
            border-radius: 2px;
            left: auto !important;
            margin: auto;
        }
    }

    .nav-menu {
        li {
            ul {
                display: none;
                position: absolute;
                background-color: rgba(236, 27, 36, 0.8);
                transform: translateX(-8px);

                li {
                    display: block;
                    float: none;
                }
            }

            &:hover {
                ul {
                    display: block;
                }
            }
        }

        > li {
            > a {
                transform: skew(-20deg);

                span {
                    transform: skew(20deg);
                    display: inline-block;
                }
            }
        }
    }
}

.carousel {
    max-height: 750px;
}

.splide {
    .splide__slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .splide__pagination__page {
        background-color: #e6555c;

        &.is-active {
            background-color: #eb1e27;
        }
    }
}

main {
    section {
        .section-title {
            padding-top: 20px;
            color: white;
            margin-bottom: 8px;

            h4 {
                padding: 10px 20px 10px 20px;
                background-color: #faa307;
                transform: skew(-20deg);
                display: inline-block;
                border-left: 8px solid #cb2630;
                border-right: 8px solid #cb2630;
                margin: 0;
                line-height: 100%;

                span {
                    display: inline-block;
                    transform: skew(20deg);
                }
            }
        }

        &.pricing {
            background-color: whitesmoke;
            margin-top: 8px;
    
            .row {
                margin-bottom: 0;
            }
    
            .card {
                .card-image {
                    max-height: 550px;
                    overflow: hidden;
    
                    img {
                        object-fit: cover;
                    }
                }
            }
        }

        &.media {
            background-color: whitesmoke;
        }
    }

    .slider-projects {
        .splide__slide {
            max-height: 450px;
            padding-left: 8px;
            padding-right: 8px;
            opacity: 0.5;

            &.is-active {
                opacity: 1.0;
            }
        }
    }
}

.page-footer {
    background-color: #484848;

    .footer-copyright {
        background-color: rgba(0, 0, 0, 0.08);
    }
}