@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "../../../node_modules/materialize-css/dist/css/materialize.css";

html {
    font-family: "Roboto", sans-serif;
    // background-color: slateblue;
    background-color: #bbd0ff;
    height: 100%;
}

body {
    font-family: "Roboto", sans-serif;
    height: 100%;
}

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {

    // Focused input style
    &:focus:not([readonly]) {
      border-bottom: 1px solid $fd-color-blue;
      box-shadow: 0 1px 0 0 $fd-color-blue;
    }

    // Focused label style
    &:focus:not([readonly])+label {
      color: $fd-color-blue;
    }
}

.btn, .btn-large, .btn-small {
    background-color: $fd-button-color;

    &:hover {
      background-color: $fd-button-hover-color;
    }
}